
import { defineComponent } from 'vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import SolanaApp from './components/layouts/SolanaApp.vue';
export default defineComponent({
  components: {
    Header,
    Footer,
    SolanaApp,
  },
  setup() {},
});
