import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { flare } from "@flareapp/flare-client";
import { flareVue } from "@flareapp/flare-vue";

const app = createApp(App)

flare.light(process.env.VUE_APP_FLAREAPP_KEY);
app.use(flareVue);
app.use(router)
app.use(VueToast)
app.mount('#app')
